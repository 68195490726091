import React from "react";
import { Route } from 'react-router-dom';
import HomeComponentBMA from './HomeComponent/HomeComponentBMA';
import ScrollUpButton from "react-scroll-up-button";
import TopBar from './TopBar/TopBar';
import Footer from '../components/Footer/Footer'
import Showroom from './Showroom/Showroom';
import GwmComponent from './GwmComponent';
import SellVehicle from './SellVehicle/SellVehicle';
import DetailedVehicle from './DetailedVehicle/DetailedVehicle';
import VehicleFinance from './Finance/VehicleFinance';
import ContactUs from './ContactUs/ContactUs';
import SpecialDeals from './SpecialDeals/SpecialDeals';
import DetailedSpecialDeals from './DetailedSpecialDeals/DetailedSpecialDeals';
import AboutUsBma from "./AboutUs/AboutUsBma";
import Popi from './Legal/Popia';
import Cookies from './Legal/Cookies';
import Terms from './Legal/Terms';
import AfterSales from "./AfterSales/AfterSales";
import Service from './BookService/BookService';
import Parts from './Parts/Parts';
import DetailedUsedVehicle from './DetailedUsedVehicle/DetailedUsedVehicle';
import Events from './events/Events'
import EventDetailed from './events/EventDetailed'
import Accessories from './Accessories/Accessories'
import UsedVehicles from "./UsedVehicles/UsedVehicles";

export default function DashboardContainer() {
  return (
    <React.Fragment>
      <main>
        <TopBar link1Text='HOME' link2Text='Vehicles in stock' link3Text='Sell' link4Text='Finance' link5Text='Contact Us' link6Text='Compare' link7Text='Favourites' />
        <Route exact path="/" render={(props) => <HomeComponentBMA />} />
        <Route exact path="/after-sales" render={(props) => <AfterSales />} />
        <Route exact path='/service' component={Service} />
        <Route exact path='/parts' component={Parts} />
        <Route exact path="/used-vehicles" render={(props) => <UsedVehicles />} />
        <Route exact path="/show-room" render={(props) => <Showroom />} />
        <Route exact path="/about" render={(props) => <AboutUsBma />} />
        <Route path="/show-room/:model/:stockId" render={(props) => <DetailedVehicle />} />
        <Route exact path='/gwm-brands' component={GwmComponent} />
        <Route path="/pre-owned/:model/:stockId" render={(props) => <   DetailedUsedVehicle />} />
        <Route path="/special-deals/:model/:stockId" render={(props) => <DetailedSpecialDeals />} />
        <Route exact path="/sell-your-vehicle" render={(props) => <SellVehicle />} />
        <Route exact path="/special-deals" render={(props) => <SpecialDeals />} />
        <Route exact path='/apply-for-finance' component={VehicleFinance} />
        <Route exact path='/contact-us' component={ContactUs} />
        <Route path='/requestergws' component={Popi} />
        <Route path='/cookies' component={Cookies} />
        <Route path='/terms' component={Terms} />
        <Route path='/events' component={Events} />
        <Route path='/event/:event_id' component={EventDetailed} />
        <Route path='/accessories' component={Accessories} />
        <Footer />
        <ScrollUpButton />
      </main>
    </React.Fragment>
  );
}