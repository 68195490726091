import React, { useContext, useState, useEffect } from 'react'
import { DealerContext } from '../contexts/DealerContext'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {
    margin: "20px 0px",
  },
  img: {
    maxWidth: "100%",
    width: "auto",
  },
  holderFlex: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: '80%',
    margin: '0 auto',
    position: 'relative',
    flexWrap: "wrap"
  },
  holder: {
    minWidth: 'calc(100% / 6 - 40px)',
    maxWidth: 'calc(100% / 6 - 40px)',
    margin: '20px',
    height: '200px',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px',
    border: '1px solid #6f6f6f',
    cursor: 'pointer',
    borderRadius: '5px',
    '@media (max-width: 613px)': {
      minWidth: 'unset',
      maxWidth: 'unset',
      width: '100%'
    },
    '@media (min-width: 614px) and (max-width: 1023px)': {
      minWidth: 'calc(100% / 2 - 40px)',
      maxWidth: 'calc(100% / 2 - 40px)',
    }
  },
  HeadingHolder: {
    display: 'flex'
  },
  HeadingBlue: {
    color: '#080b2f',
    fontSize: '40px',
    fontWeight: 'bold',
    width: '80%',
    margin: '0 auto',
    zIndex: 2,
    textTransform: 'uppercase',
  },
  HeadingBlueOverlay: {
    color: '#e6e6e6',
    position: 'absolute',
    margin: '0 auto',
    top: '-53px',
    fontSize: '220px',
    right: '0',
    pointerEvents: 'none',
    textTransform: 'uppercase',
    '@media (max-width: 959px)': {
      fontSize: '80px',
      top: '-3px',
    }
  }
}))

const BrandList = () => {
  const classes = useStyles();
  const { dealerList } = useContext(DealerContext);
  const [slides, setSlides] = useState([]);
  const cleanName = (dealerName) => {
    return dealerName.toLowerCase().replace(/\//g, '-').replace(/ /g, '-')
  }

  useEffect(() => {
    let makes = []
    for (let index = 0; index < dealerList?.length; index++) {
      const element = dealerList[index];
      let obj = { brands: element?.relatedBrands[0], dealer: element }
      makes.push(obj)
    }
    setSlides(makes.filter((m) => m.dealer.dealerId !== 497 && m.dealer.dealerId !== 501));
  }, [dealerList])

  const handleDealerChange = (dealer) => {
    console.log(dealer);
    {dealer?.dealerId === 509 || dealer?.dealerId === 501 ? window.location.href = `/gwm-brands` : window.location.href = `/dealer/${cleanName(dealer.name)}`}
    
  }

  return (
    <div className={classes.root}>
      <div className={classes.HeadingHolder}>
        <div className={classes.HeadingBlue}>Our Brands</div>
        <div className={classes.HeadingBlueOverlay}>Brands</div>
      </div>
      <div className={classes.holderFlex}>
        {slides?.map((slide) => {
          return (
            <div className={classes.holder} onClick={() => { handleDealerChange(slide?.dealer) }}><img src={slide?.brands?.logo} width="80%" /></div>
          )
        })}
      </div>
    </div>
  );
}

export default BrandList